import useFetch from "@Hooks/UseFetch";
import * as Constants from "../../constants";
import {PublicTournamentList} from "@Types/PublicTournamentList";

const TournamentList = () => {
    const {
        response: { loading, data, status_code, error }
    } = useFetch<PublicTournamentList>(Constants.BASE_URL + "/api/tournaments/list");

    if (error) {
        return <div className="container">Error collecting account data</div>;
    }
    if (loading) {
        return <div className="container">Loading...</div>;
    }

    return (
        <div>
            <h2>Tournaments</h2>
            {data?.map(tournament => (
                <div className="tournament-container">
                    <h3>{tournament.name}</h3>
                    <a href={"https://challonge.com/" +  tournament.challonge_id} className="btn">Challonge</a>
                </div>
            ))}
        </div>
    );
};

export default TournamentList;
