import useFetch from '@Hooks/UseFetch';
import { DiscordUser } from '@Types/DiscordUser';
import * as Constants from '../../constants';
import {useLocation, useNavigate} from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useAppSelector } from '@Hooks/UseAppSelector';
import { User } from '@Types/User';
import { useAppDispatch } from '@Hooks/UseAppDispatch';
import { update } from '../../stores/authorizationStore';
import { useEffect } from 'react';

const Registration = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { register, handleSubmit } = useForm();

  const isRegistered = useAppSelector((state) => state.authorization.registered);
  const user = useAppSelector((state) => state.authorization.user);

  const {
    response: { data, loading, error },
  } = useFetch<DiscordUser>(Constants.BASE_URL + '/api/@me/discord', {
    credentials: 'include',
  });

  useEffect(() => {
    if ((isRegistered && location.pathname === "/register") || error) {
      navigate('/');
    }
  }, [isRegistered, error, location.pathname, navigate]);

  if (isRegistered && location.pathname === "/register") {
    return null;
  }

  if (error) {
    return <div className="container">Error collecting account data</div>;
  }
  if (loading) {
    return <div className="container">Loading...</div>;
  }

  const onSubmit = async (form: any) => {
    try {
      const result = await fetch(Constants.BASE_URL + '/api/user/create', {
        credentials: 'include',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          display_name: (form.display_name === null || form.display_name === '') ? null : form.display_name,
          twitch_name: form.twitch,
          scoresaber_id: form.scoresaber,
        }),
      });

      if (result.status !== 200 && result.status !== 204) {
        alert('Request failed!');
      } else {
        try {
          let user: User = (await result.json()) as User;
          dispatch(update({ loading: false, loggedIn: true, registered: true, user: user }));
        } catch (e) {
          alert('Invalid user data');
        }
      }
    } catch (error) {
      console.log('error on user creation');
    }
  };

  return (
    <div className="container">
      <img src={data?.avatar} alt="Avatar" id="dc-avatar" /> <p>{isRegistered ? "Registered" : "Register"} as</p>
      <h2 id="dc-name">
        {data?.username}#{data?.discriminator}
      </h2>
      <form name="profile_data" onSubmit={handleSubmit(onSubmit)}>
        <input className="name" type="text"
               placeholder={'Display Name (' + data?.username + ')'}
               defaultValue={ isRegistered ? user?.display_name : undefined }
               {...register('display_name')} />
        <input className="name" type="text"
               placeholder="Twitch Username"
               defaultValue={ isRegistered ? user?.twitch_name : undefined }
               {...register('twitch', { required: true })} />
        <input className="name" type="text"
               placeholder="ScoreSaber ID"
               defaultValue={ isRegistered ? user?.scoresaber_id : undefined }
               {...register('scoresaber', { required: true })} />
        <input className="login" type="submit" value={isRegistered ? "UPDATE" : "REGISTER"} />
      </form>
    </div>
  );
};

export default Registration;
