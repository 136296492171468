import {Link, Outlet, useLocation, useNavigate} from "react-router-dom";
import * as Constants from "../../constants"
import {useAppSelector} from "@Hooks/UseAppSelector";
import {useAppDispatch} from "@Hooks/UseAppDispatch";
import {loadAsync, update} from "../../stores/authorizationStore";
import {useEffect} from "react";

const Wrapper = () => {
    let initial = true;
    const dispatch = useAppDispatch();
    const location = useLocation();
    const navigate = useNavigate();

    const isLoggedIn = useAppSelector((state) => state.authorization.loggedIn);
    const isRegistered = useAppSelector((state) => state.authorization.registered);
    const state = useAppSelector((state) => state.authorization.state)
    const user = useAppSelector((state) => state.authorization.user);

    const logout = async () => {
        let resp = await fetch(Constants.BASE_URL + "/api/logout", {
            method: "PATCH",
            credentials: "include"
        });
        if (resp.status === 200 || resp.status === 204) {
            dispatch(update({state: 'done', loggedIn: false, registered: false, user: undefined}));
        } else {
            alert("Error when sending logout request...");
        }
    }

    useEffect(() => {
        dispatch(loadAsync());
    }, [dispatch, initial])

    useEffect(() => {
        if (isLoggedIn && !isRegistered && location.pathname !== "/register") {
            navigate("/register");
        }
    }, [isLoggedIn, isRegistered, location.pathname, navigate]);

    let login;
    if (state === 'initial' || state === 'loading') {
        login = (
            <div>
                <p>Loading...</p>
            </div>
        );
    } else if (isLoggedIn) {
        if (!isRegistered) {
            if (location.pathname !== "/register") {
                return null;
            }
        } else {
            login = (
                <div>
                    <p>Logged in as {user?.display_name}</p>
                    <button className="btn" onClick={logout}>Logout</button>
                    <Link to="/profile" id="avt"><img src={user?.discord.avatar}/></Link>
                </div>
            );
        }
    } else {
        login = (
            <div>
                <a href={Constants.BASE_URL + "/api/login"} className="btn">Login</a>
            </div>
        );
    }

    return (
        <div id="content">
            <div id="profile">
                {login}
            </div>
            <div id="outlet">
               <Outlet/> 
            </div>
        </div>
    );
};

export default Wrapper;
