import * as Constants from "../../../constants";
import {useNavigate, useParams} from "react-router-dom";
import {useAppSelector} from "@Hooks/UseAppSelector";
import {MutableRefObject, useEffect, useRef, useState} from "react";

interface RoomState {
    current_game_phase: 'INITIAL' | 'WAITING_FOR_PLAYERS' | 'PICKS_AND_BANS' | 'WAITING_FOR_START' | 'IN_MAP' | 'REPORTING_RESULTS' | 'FINISHED'
}

const CoordinatorMatchFlow = () => {
    const state = useAppSelector((state) => state.authorization.state);
    const loggedIn = useAppSelector((state) => state.authorization.loggedIn);
    const navigate = useNavigate();
    let {id, match} = useParams();

    const webSocketRef = useRef<WebSocket | undefined>(undefined);

    const [shouldClose, setShouldClose] = useState(false);
    const [roomState, setRoomState] = useState<RoomState | undefined>(undefined);

    useEffect(() => {
        setShouldClose(false);
        reconnect(webSocketRef);
        return function cleanup() {
            setShouldClose(true);
            webSocketRef.current?.close();
            webSocketRef.current = undefined;
        }
    }, []);

    const closeOrErrorHandler = (ev: any) => {
        console.log("WebSocket closed!")
        if (shouldClose) {
            setShouldClose(false);
        } else {
            setTimeout(() => {
                reconnect(webSocketRef);
            }, 100);
        }
    };

    const messageReceiveHandler = (ev: MessageEvent) => {
        setRoomState(JSON.parse(ev.data) as RoomState);
    };

    const initializeWebsocket = () => {
        let websocket = new WebSocket(Constants.WS_BASE_URL + "/api/tournament/" + id + "/match/" + match + "/ws");
        websocket.addEventListener("message", messageReceiveHandler);
        websocket.addEventListener("close", closeOrErrorHandler);
        websocket.addEventListener("error", closeOrErrorHandler);
        return websocket;
    };

    const reconnect = (webSocketRef: MutableRefObject<WebSocket | undefined>) => {
        console.log("(Re)Connecting!");
        webSocketRef.current = initializeWebsocket();
    };


    useEffect(() => {
        if ((state === "done" || state === "error") && !loggedIn) {
            navigate("/");
        }
    }, [state, loggedIn, navigate])

    useEffect(() => {
        if (roomState === undefined) {
            return;
        }
        let new_state;
        if (roomState?.current_game_phase === 'INITIAL') {
            new_state = 'PICKS_AND_BANS'
        } else if (roomState?.current_game_phase === 'PICKS_AND_BANS') {
            new_state = 'FINISHED'
        } else {
            return;
        }
        let new_state_json = JSON.stringify({
            current_game_phase: new_state,
        });
        console.log("Sending: " + new_state_json);
        webSocketRef.current?.send(new_state_json);
    }, [roomState]);

    console.log("Rendering", roomState);
    if (roomState === undefined) {
        return <p>Loading...</p>
    }

    return (
        <div className="container">
            <p>{roomState?.current_game_phase}</p>
        </div>
    );
};

export default CoordinatorMatchFlow;
