import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter, Routes, Route} from "react-router-dom";

import './index.css';

import Home from "@Components/Home";
import Wrapper from "@Components/wrapper";
import Registration from "@Components/registration";
import TournamentList from "@Components/tournamentList";

import CoordinatorMatchSelect from "@Components/coordination/matchSelect";
import CoordinatorTournamentSelect from "@Components/coordination/tournamentSelect";
import CoordinatorMatchFlow from "@Components/coordination/matchFlow";

import {NotFound} from "@Components/errorPages";

import reportWebVitals from './reportWebVitals';
import {Provider} from "react-redux";
import authorizationStore from "./stores/authorizationStore";

ReactDOM.render(
    <React.StrictMode>
        <Provider store={authorizationStore}>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Wrapper/>}>
                        <Route path="/" element={<Home/>}/>
                        <Route path="/register" element={<Registration/>}/>
                        <Route path="/profile" element={<Registration/>}/>
                        <Route path="/tournaments" element={<TournamentList/>}/>

                        <Route path="/coordinator" element={<CoordinatorTournamentSelect/>}/>
                        <Route path="/coordinator/tournament/:id" element={<CoordinatorMatchSelect/>}/>
                        <Route path="/coordinator/tournament/:id/match/:match" element={<CoordinatorMatchFlow/>}/>

                        <Route path="*" element={<NotFound/>}/>
                    </Route>
                </Routes>
            </BrowserRouter>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
