import useFetch from "@Hooks/UseFetch";
import * as Constants from "../../../constants";
import {Link, useNavigate, useParams} from "react-router-dom";
import {useAppSelector} from "@Hooks/UseAppSelector";
import {useEffect} from "react";
import {ChallongeMatchList} from "@Types/ChallongeMatchList"

const CoordinatorMatchSelect = () => {
    const state = useAppSelector((state) => state.authorization.state);
    const loggedIn = useAppSelector((state) => state.authorization.loggedIn);
    const navigate = useNavigate();
    let {id} = useParams();

    const {
        response: {loading, data, status_code, error}
    } = useFetch<ChallongeMatchList>(Constants.BASE_URL + "/api/tournament/" + id + "/matches", {
        credentials: "include"
    });

    useEffect(() => {
        if (((state === "done" || state === "error") && !loggedIn) || status_code === 401) {
            navigate("/");
        } else if (status_code === 403) {
            navigate("/coordinator");
        }
    }, [state, loggedIn, navigate, status_code])

    if (error) {
        return <div className="container">Error fetching data</div>;
    }
    if (loading) {
        return <div className="container">Loading...</div>;
    }

    return (
        <div className="container">
            {data?.map(match => (
                <div className="match">
                    <h3>Round {match.match.round} Match {match.match.identifier} </h3>
                    <Link to={"/coordinator/tournament/" + id + "/match/" + match.match.id}>Coordinate</Link>
                </div>
            ))}
        </div>
    );
};

export default CoordinatorMatchSelect;
