import {configureStore, createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {User} from "@Types/User";
import * as Constants from "../constants"

export interface AuthorizationState {
    state: 'initial' | 'loading' | 'done' | 'error',
    loggedIn: boolean,
    registered: boolean,
    user: User | undefined,
}

const initialDataPayload: AuthorizationState = {state: 'initial', loggedIn: false, registered: false, user: undefined};

export const loadAsync = createAsyncThunk(
    'api/authorization',
    async () => {
        let response = await fetch(Constants.BASE_URL + "/api/@me", {
            credentials: "include"
        })
        if (response.status === 200) {
            let user = (await response.json()) as User;
            return {
                user: user,
                status: response.status,
            }
        } else {
            return {
                user: undefined,
                status: response.status,
            };
        }
    }
)

export const updateAuthorizationSlice = createSlice({
    name: "AUTHORIZATION",
    initialState: initialDataPayload,
    reducers: {
        update: (state, {payload}) => {
            if (state === payload) {
                return;
            }
            return payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(loadAsync.pending, (state) => {
                state.state = 'loading';
            })
            .addCase(loadAsync.fulfilled, (state, action) => {
                if (action.payload.status === 401) {
                    state.loggedIn = false;
                    state.registered = false;
                    state.state = 'done';
                } else if (action.payload.status === 404) {
                    state.loggedIn = true;
                    state.registered = false;
                    state.state = 'done';
                } else if (action.payload.status === 200) {
                    state.loggedIn = true;
                    state.registered = true;
                    state.user = action.payload.user
                    state.state = 'done';
                } else {
                    state.state = 'error';
                }
            })
            .addCase(loadAsync.rejected, (state) => {
                state.loggedIn = false;
                state.registered = false;
                state.state = 'error';
            })
    }
})

export const {update} = updateAuthorizationSlice.actions;

export const reducer = updateAuthorizationSlice.reducer;

const store = configureStore({
    reducer: {
        authorization: reducer,
    },
});


export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export default store;
