import {useEffect, useReducer, useRef} from 'react';

interface Response<T> {
    data?: T;
    loading?: Boolean;
    status_code?: number;
    error?: Error;
}

interface State<T> {
    response: Response<T>;
}

// discriminated union type
type Action<T> = { type: 'response'; payload: State<T> };

/**
 * This is a custom usefetch hook I made
 * @param {string} url
 * @param {RequestInit} options
 * @returns {State}
 **/
function useFetch<T>(url: string, options?: RequestInit): State<T> {
    // Used to prevent state update if the component is unmounted
    const cancelRequest = useRef<boolean>(false);

    const initialState: State<T> = {
        response: {loading: true, data: undefined, status_code: undefined, error: undefined},
    };

    // Keep state logic separated
    const fetchReducer = (state: State<T>, action: Action<T>): State<T> => {
        switch (action.type) {
            case 'response':
                return {...initialState, ...action.payload};
            default:
                return state;
        }
    };

    const [state, dispatch] = useReducer(fetchReducer, initialState);

    useEffect(() => {
        // Do nothing if the url is not given
        if (!url) return;

        const fetchData = async () => {
            dispatch({type: 'response', payload: {response: {...state.response, loading: true}}});

            try {
                const response = await fetch(url, options);
                let status_code = response.status;
                if (!response.ok) {
                    dispatch({
                        type: 'response',
                        payload: {
                            response: {
                                ...state.response,
                                status_code,
                                error: new Error(response.statusText) as Error,
                                loading: false
                            }
                        }
                    });
                    return;
                }

                const data = (await response.json()) as T;
                if (cancelRequest.current) return;
                dispatch({type: 'response', payload: {response: {...state.response, data, status_code, loading: false}}});
            } catch (error) {
                if (cancelRequest.current) return;

                dispatch({
                    type: 'response',
                    payload: {response: {...state.response, error: error as Error, status_code: 0, loading: false}}
                });
            }
        };

        void fetchData();

        // Use the cleanup function for avoiding a possibly...
        // ...state update after the component was unmounted
        return () => {
            cancelRequest.current = true;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [url]);

    return state;
}

export default useFetch;
