import {useAppSelector} from "@Hooks/UseAppSelector";
import {useNavigate} from "react-router-dom";
import {useForm} from "react-hook-form";
import {useEffect} from "react";

const CoordinatorTournamentSelect = () => {
    const state = useAppSelector((state) => state.authorization.state);
    const loggedIn = useAppSelector((state) => state.authorization.loggedIn);
    const navigate = useNavigate();
    const {register, handleSubmit} = useForm();

    useEffect(() => {
        if ((state === "error" || state === "done") && !loggedIn) {
            navigate("/");
        }
    }, [state, loggedIn, navigate])

    const onSubmit = (form: any) => {
        navigate("/coordinator/tournament/" + form.challonge_id);
    };

    return (
        <div className="container">
            <form name="tournament_form" onSubmit={handleSubmit(onSubmit)}>
                <input className="name" type="text"
                       placeholder="Challonge ID"
                       {...register('challonge_id', {required: true})} />
                <input className="button" type="submit" value={"Start Coordinating"}/>
            </form>
        </div>
    );
};

export default CoordinatorTournamentSelect;
